import React from 'react';
import './App.css';

function App() {
  return (
    <>
    <div className="App">
      <h1 className="coming-soon-text">Coming Soon</h1>
    </div>
    <footer className="footer">
        <p>
          With <span className="heart">❤️</span> SimplifydCS™ team
        </p>
      </footer>
    </>
  );
}

export default App;